import React, { useState } from "react";
import Autosuggest from 'react-autosuggest';
import pagaduriasData from '../assets/pagadurias.json';
export default function InputPagadurias(props) {
    const { value, onChangeLocal } = props;
    //const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    const getSuggestions = value => {
        const escapedValue = escapeRegexCharacters(value.trim());
        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp('^' + escapedValue, 'i');
        return pagaduriasData.pagadurias.filter(language => regex.test(language.nombre_linea_ACTUAL));
    };
    const getSuggestionValue = suggestion => suggestion.nombre_linea_ACTUAL;
    const renderSuggestion = suggestion => (
        <div>
            {suggestion.nombre_linea_ACTUAL}
        </div>
    );
    /**const onChangeLocal = (event, { newValue }) => {
        setValue(newValue);
    };**/
    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };
    const inputPropsLocal = {
        placeholder: 'Busca pagaduria',
        value,
        onChange: onChangeLocal
    };
    return (
        <>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputPropsLocal}
            />
        </>
    )
}
