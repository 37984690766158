import { useSelector } from "react-redux";
export default function ModalIngresos() {
    const modal_ingresos = useSelector(({ sections }) => sections.modal_ingresos);
    return (
        <div 
        id="ingresosModal"
        className="modal fade">
            <div className="modal-dialog">
                <div className="modal-content">
                    <a
                    href={() => false}
                    data-dismiss="modal"
                    className="close-modal">x</a>
                    <h2>{modal_ingresos.titulo}</h2>
                    <div className="space-md"></div>
                    <p className="m-0">{modal_ingresos.contenido}</p>
                </div>
            </div>
        </div>
    );
}
