import { Element } from "react-scroll";
import { selectTipoCredito } from '../store/sectionsSlice';
import { updateWizardSteps } from '../store/wizardSlice';
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import recuperacion from '../assets/recuperacion.png';
import compra_cartera from '../assets/compra_cartera.png';
import retanqueo from '../assets/retanqueo.png';
import libre_inversion from '../assets/libre_inversion.png';
import main_content from '../assets/slider/main_content.png';

import ModalRequisitos from "../components/ModalRequisitos";
import MobileCredito from "../components/MobileCredito";

export default function Tipocredito(){
    const dispatch = useDispatch();
    const tipo_credito = useSelector(({ sections }) => sections.tipo_credito)
    const titulo_credito = useSelector(({ sections }) => sections.titulo_credito)
    const contenido_credito = useSelector(({ sections }) => sections.contenido_credito)
    const SelectCredito = (slug) => {
        dispatch(selectTipoCredito(slug));
        dispatch(updateWizardSteps('stepTwo'));
    }
    return (
        <Element name="stepOne" className="element">
        <div className="container">
            <div className="row">
                <MobileCredito />
                <div className="col-lg-8 col-md-12 col-sm-12">
                    <div className="titulo_container color_primario d-none d-sm-none d-md-none d-lg-block">
                        <h1>
                            Dinos lo que necesitas, para saber <br/>
                            <b>¿Como podemos ayudarte?</b>
                        </h1>
                        <p>
                            Selecciona la opcion que mejor se ajuste a tu necesidad
                        </p>
                    </div>
                    <div className="row m-md-4">
                        <div className="col-12 big-btns-container d-flex justify-content-center">
                            <div className="info-cont">
                                <div 
                                onClick={()=> SelectCredito('credito_libre_inversion')}
                                className={`big-btn2 big-btn-3 ${tipo_credito === 'credito_libre_inversion' ? "active" : ""}`} >
                                    <i>
                                        <FontAwesomeIcon 
                                        size="lg"
                                        icon={faCheckCircle}/>
                                    </i>
                                    <img 
                                    src={libre_inversion}
                                    alt="Bancarizate" />
                                    <h3>Credito Libre inversion</h3>
                                </div>
                            </div>
                            <div className="info-cont">
                                <div 
                                onClick={()=> SelectCredito('compra_cartera')}
                                className={`big-btn2 big-btn-3 ${tipo_credito === 'compra_cartera' ? "active" : ""}`} >
                                    <i>
                                        <FontAwesomeIcon 
                                        size="lg"
                                        icon={faCheckCircle}/>
                                    </i>
                                    <img 
                                    src={compra_cartera} 
                                    alt="Bancarizate" />
                                    <h3>Compra de cartera</h3>
                                </div>
                            </div>
                            <div className="info-cont">
                                <div 
                                onClick={()=> SelectCredito('recuperacion')}
                                className={`big-btn2 big-btn-3 ${tipo_credito === 'recuperacion' ? "active" : ""}`} >
                                    <i>
                                        <FontAwesomeIcon 
                                        size="lg"
                                        icon={faCheckCircle}/>
                                    </i>
                                    <img 
                                    src={recuperacion}
                                    alt="Bancarizate" />
                                    <h3>Recuperacion crediticia</h3>
                                </div>
                            </div>
                            <div className="info-cont">
                                <div 
                                onClick={()=> SelectCredito('retanqueo')}
                                className={`big-btn2 big-btn-3 ${tipo_credito === 'retanqueo' ? "active" : ""}`} >
                                    <i>
                                        <FontAwesomeIcon 
                                        size="lg"
                                        icon={faCheckCircle}/>
                                    </i>
                                    <img 
                                    src={retanqueo}
                                    alt="Bancarizate" />
                                    <h3>Retanqueo</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div 
                style={{
                    marginTop: 'auto'
                }}
                className="col-lg-4 d-none d-sm-none d-md-none d-lg-block">
                    <img 
                    style={{
                        width: '450px'
                    }}
                    src={main_content}
                    alt="Bancarizate main"
                    />
                </div>
            </div>
        </div>
        {titulo_credito ? <div className="container">
            <div className="titulo_container color_primario">
                    <h1>
                        <b>{titulo_credito}</b>
                    </h1>
                </div>
                <div className="row m-4">
                    <p className="text-justify color_primario">
                        {contenido_credito}
                    </p>
                    <div className="col-md-12 m-md-auto m-sm-4 d-flex justify-content-center ">
                        <button
                        data-toggle="modal" 
                        data-target="#requisitosModal"
                        className="btn btn-outline-primary"
                        style={{
                            fontSize: 18, 
                            padding: 15,
                            borderColor: '#000080'
                        }}>
                            Ver requisitos
                        </button>
                    </div>
                </div>
            <ModalRequisitos />
        </div> : null}
        </Element>
    )
}