import axios from "axios";
import {
    ADD_CONTACT_BITRIX,
    UPDATE_CONTACT_BITRIX,
    ADD_LEAD_BITRIX,
    GET_TOKEN_RECONOSERID,
    GET_URI_RECONOSERID
} from "./constants";
const webhooks = {
    async sendBitrix_contact(data){
        try {
            var contactURI = await axios.post(ADD_CONTACT_BITRIX, {
                "fields": {
                    ...data
                }
            });
            return contactURI.data;
        } catch (error) {
            return false;
        }
    },
    async sendBitrix_contact_update(id, data = {}){
        try {
            var contactURI = await axios.post(UPDATE_CONTACT_BITRIX, {
                "id": id,
                "fields": {
                    ...data
                }
            });
            return contactURI.data;
        } catch (error) {
            return false;
        }
    },
    async sendBitrix_lead(data){
        var contact_data = await this.sendBitrix_contact(data.contact);
        try {
            var leadURI = await axios.post(ADD_LEAD_BITRIX, {
                "fields": {
                    ...data.lead,
                    "CONTACT_ID": contact_data.result
                }
            });
            return {
                ...leadURI.data,
                contact_id: contact_data.result 
            };
        } catch (error) {
            return false;
        }
    },
    /**Endpoints reconoserID */
    async generate_token_reconocer(){
        var tokenURI = axios.post(GET_TOKEN_RECONOSERID, {
            "clientId": "RQ PRATE",
            "clientSecret": "rQpr4T3P@$$w0rd"
        });
        return await tokenURI.json();
    },
    async generate_reconoser_uri(){
        var tokenReconoser = this.generate_token_reconocer();
        var config = {
            headers: { Authorization: `Bearer ${tokenReconoser.accessToken}` }
        };
        var tokenURI = axios.post(GET_URI_RECONOSERID, {
            "guidConv": "3561a028-c04f-4cea-8122-371548af10cd",
            "tipoValidacion": 1,
            "asesor": "asesorT",
            "sede": "000100",
            "codigoCliente": "0000012436",
            "tipoDoc": "CC",
            "numDoc": "1143842533",
            "email": "websterh18@gmail.com",
            "celular": "3112568978",
            "usuario": "RQPRATE",
            "clave": "12345",
            "infCandidato": "probandocandidato"
        }, config);
        return await tokenURI.json();
    }
}
export default webhooks;