import { selectIngresos, openIngresos } from '../store/sectionsSlice';
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import salario from '../assets/salario.png';
import servicios from '../assets/servicios.png';
import pension from '../assets/pension.png';
import independiente from '../assets/independiente.png';

import { Element } from "react-scroll";

import ModalIngresos from '../components/ModalIngresos';

export default function Origeningresos() {
    const dispatch = useDispatch();
    const ingresos = useSelector(({ sections }) => sections.ingresos);
    const contacto_form = useSelector(({ sections }) => sections.contacto_form);
    const modal_ingresos = useSelector(({ sections }) => sections.modal_ingresos);
    const SelectOrigen = (slug) => {
        dispatch(selectIngresos(slug));
    }
    const modalInfo = (e, slug) => {
        e.preventDefault();
        var info = {
            open: !modal_ingresos.open,
            slug: slug
        }
        dispatch(openIngresos(info));
    }
    const ingresos_span = () => ingresos.map((ingreso) => <span>{ingreso} </span>) ;

    return (
        <Element name="stepThree" className="element">
        <div className="container">
            <div className="titulo_container color_primario">
                <h1>
                    {contacto_form.nombres} {contacto_form.apellidos}, por favor <br/>
                    <b>Indicanos el origen de tus ingresos</b>
                </h1>
                <p>
                    De ser el caso selecciona multiples opciones
                </p>
                <hr />
            </div>
            <div className="row m-md-4">
                <div className="col-12 big-btns-container d-flex justify-content-center">
                    <div className="info-cont">
                        <div 
                        onClick={()=> SelectOrigen('salario')}
                        className={`big-btn big-btn-3 ${ingresos.includes('salario') ? "active" : ""}`} >
                            <i>
                                <FontAwesomeIcon 
                                size="lg"
                                icon={faCheckCircle}/>
                            </i>
                            <img 
                            src={salario} 
                            alt="Bancarizate" />
                            <h3>Salario</h3>
                        </div>
                        <a 
                        onClick={(e) => modalInfo(e, 'salario')}
                        data-toggle="modal" 
                        data-target="#ingresosModal"
                        style={{cursor: 'pointer'}}
                        href={() => false}>Más info</a>
                    </div>
                    <div className="info-cont">
                        <div 
                        onClick={()=> SelectOrigen('pension')}
                        className={`big-btn big-btn-3 ${ingresos.includes('pension') ? "active" : ""}`}>
                            <i>
                                <FontAwesomeIcon 
                                size="lg"
                                icon={faCheckCircle}/>
                            </i>
                            <img 
                            src={pension} 
                            alt="Bancarizate" />
                            <h3>Pension</h3>
                        </div>
                        <a 
                        onClick={(e) => modalInfo(e, 'pension')}
                        data-toggle="modal" 
                        data-target="#ingresosModal"
                        style={{cursor: 'pointer'}}
                        href={() => false}>Más info</a>
                    </div>
                    <div className="info-cont">
                        <div 
                        onClick={()=> SelectOrigen('independiente')}
                        className={`big-btn big-btn-3 ${ingresos.includes('independiente') ? "active" : ""}`}>
                            <i>
                                <FontAwesomeIcon 
                                size="lg"
                                icon={faCheckCircle}/>
                            </i>
                            <img 
                            src={independiente} 
                            alt="Bancarizate" />
                            <h3>Independiente</h3>
                        </div>
                        <a 
                        onClick={(e) => modalInfo(e, 'independiente')}
                        data-toggle="modal" 
                        data-target="#ingresosModal"
                        style={{cursor: 'pointer'}}
                        href={() => false}>Más info</a>
                    </div>
                    <div className="info-cont">
                        <div 
                        onClick={()=> SelectOrigen('servicios')}
                        className={`big-btn big-btn-3 ${ingresos.includes('servicios') ? "active" : ""}`}>
                            <i>
                                <FontAwesomeIcon 
                                size="lg"
                                icon={faCheckCircle}/>
                            </i>
                            <img 
                            src={servicios} 
                            alt="Bancarizate" />
                            <h3>Prestacion servicios</h3>
                        </div>
                        <a 
                        onClick={(e) => modalInfo(e, 'servicios')}
                        data-toggle="modal" 
                        data-target="#ingresosModal"
                        style={{cursor: 'pointer'}}
                        href={() => false}>Más info</a>
                    </div>
                </div>
            </div>
            <div className="titulo_container color_primario">
                <h1>
                    Muy bien {contacto_form.nombres} {contacto_form.apellidos}, para analizar tu solicitud,<br/>
                    <b>Necesitamos entender mejor tus ingresos</b>
                </h1>
                <hr />
                <p>
                {contacto_form.nombres} {contacto_form.apellidos}, has indicado que tus ingresos provienen de la(s) actividad(es), { ingresos_span() }.
                </p>
            </div>
        </div>
        <ModalIngresos />
        </Element>
    )
}
