
import HeaderWP from '../components/HeaderWP';
import Slider from '../sections/Slider';
//import Maincontent from '../sections/Maincontent';
import Tipocredito from '../sections/Tipocredito';
import Contactoform from '../sections/Contactoform';
import Origeningresos from '../sections/Origeningresos';
import Salarioform from '../sections/Salarioform';
import Pensionform from '../sections/Pensionform';
import Independienteform from '../sections/Independienteform';
import Deudasform from '../sections/Deudasform';
import Wizard from '../components/Wizard';
import MainLoader from '../components/MainLoader';
import Serviciosform from '../sections/Serviciosform';
import DiagonalBox from '../sections/DiagonalBox';

import { useSelector } from "react-redux";

export default function Landing() {
    const wizard_steps = useSelector(({ wizard }) => wizard.wizard_steps);
    const ingresos = useSelector(({ sections }) => sections.ingresos);
    const loader_enviando = useSelector(({ sections }) => sections.loader_enviando);
    return (
        <>
            {loader_enviando ? <MainLoader /> : null }
            <HeaderWP />
            <main>
                <Slider />
                <DiagonalBox />
                {/*<Maincontent />*/}
                <Tipocredito />
                { wizard_steps.includes('stepTwo') ? <Contactoform />:null }
                { wizard_steps.includes('stepThree') ? <Origeningresos /> :null }
                { ingresos.includes('salario')?<Salarioform />:null }
                { ingresos.includes('pension')?<Pensionform />:null }
                { ingresos.includes('servicios')?<Serviciosform />:null }
                { ingresos.includes('independiente')?<Independienteform />:null }
                { wizard_steps.includes('stepFive') ? <Deudasform /> : null }
                <div style={{
                    marginBottom: 120
                }}>
                </div>
            </main>
            <Wizard />
        </>
    )
}
