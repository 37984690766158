import { createSlice } from '@reduxjs/toolkit';

const wizardSlice = createSlice({
    name: 'wizard',
    initialState: {
        wizard_steps: ['stepOne']
    },
    reducers: {
        updateWizardSteps(state, action) {
            if(!state.wizard_steps.includes(action.payload)){
                state.wizard_steps = [...state.wizard_steps, action.payload];
            }
        }
    },
})
export const { 
    updateWizardSteps
} = wizardSlice.actions
export default wizardSlice.reducer