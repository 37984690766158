import React, { useState } from "react";
import Autosuggest from 'react-autosuggest';
import ciudadesData from '../assets/ciudades.json';
import { updateContacto } from '../store/sectionsSlice';
import { useDispatch } from "react-redux";
export default function InputCiudades() {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    const getSuggestions = value => {
        const escapedValue = escapeRegexCharacters(value.trim());
        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp('^' + escapedValue, 'i');
        return ciudadesData.ciudades.filter(ciudad => regex.test(ciudad.ciudad));
    };
    const getSuggestionValue = suggestion => suggestion.ciudad+' - '+suggestion.depto;
    const renderSuggestion = suggestion => (
        <div>
            {suggestion.ciudad+' - '+suggestion.depto}
        </div>
    );
    const onChangeLocal = (event, { newValue }) => {
        setValue(newValue);
        dispatch(updateContacto({ciudad: newValue}));
    };
    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };
    const inputPropsLocal = {
        placeholder: 'Busca ciudad',
        value,
        onChange: onChangeLocal
    };
    return (
        <>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputPropsLocal}
            />
        </>
    )
}
