import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ReactNumeric from 'react-numeric';
import { useDispatch, useSelector } from "react-redux";
import { addPension, setPension, removePension } from '../store/sectionsSlice';
import { Element } from "react-scroll";
import InputPagadurias from "../components/InputPagadurias";
export default function Pensionform() {
    const dispatch = useDispatch();
    const pensionesList = useSelector(({ sections }) => sections.pensiones);
    const [morePensiones, setMorePensiones] = useState(false);
    const addPensionBtn = () => {
        if(pensionesList.length <= 2){
            dispatch(addPension());
            setMorePensiones(false)
        }else{
            setMorePensiones(true)
        }
    }
    const deletePensionBtn = (index) => {
        dispatch(removePension(index));
    }
    /**const saveData = (event, index) => {
        var data = {
            index,
            field: event.target.name,
            value: event.target.value   
        }
        dispatch(setPension(data));
    }**/
    const savePagaduria = (newValue, index) => {
        var data = {
            index,
            field: 'pagaduria',
            value: newValue   
        }
        dispatch(setPension(data));
    }
    const savePrice = (event, index, value) => {
        var data = {
            index,
            field: event.target.name,
            value: value 
        }
        dispatch(setPension(data));
    }
    /**const saveFile = (event, index) => {
        const { files } = event.target;
        const localImageUrl =  window.URL.createObjectURL(files[0]);
        var data = {
            index,
            field: event.target.name,
            value: {
                file: localImageUrl,
                nombre: files[0].name
            }   
        }
        dispatch(setPension(data));
    }**/
    return (
        <Element name="pension" className="element">
        <div className="container">
            {morePensiones ?
            <div className="row m-4">
                <div className="col-md-12">
                    <div className="alert alert-danger" role="alert">
                        Maximo 3 pensiones!
                    </div>
                </div>
            </div>
            : null}
            { pensionesList.map((pensionItem, index) => 
            <div key={index} className="row m-4">
                <div className="col-md-12 color_secundario">
                    <h1><b>Sobre tu Pension 
                        {index === 0 ? null : `# ${index+1}` }</b></h1>
                    <hr className="divider_titulo" />
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url5">Devengado</label>
                    <div className="input-group mb-3">
                        <ReactNumeric
                            id="basic-url5"
                            className="form-control" 
                            currencySymbol="$ "
                            minimumValue="0"
                            decimalCharacter=","
                            decimalPlaces="0"
                            digitGroupSeparator="."
                            name="devengado"
                            value={pensionItem.devengado} 
                            onChange={(event, value) => {
                                savePrice(event, index, value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url6">Indicanos Pagaduria:</label>
                    <div className="input-group mb-3">
                        <InputPagadurias
                        value={pensionItem.pagaduria} 
                        onChangeLocal={
                            (event, { newValue }) => {
                                savePagaduria(newValue, index);
                            }
                        }
                        name="pagaduria"
                        />
                        {/*<input 
                        name="pagaduria"
                        value={pensionItem.pagaduria} 
                        onChange={(event) => {
                            saveData(event, index);
                        }}
                        type="text" 
                        className="form-control" 
                        id="basic-url6" 
                        aria-describedby="basic-addon3" />*/}
                    </div>
                </div>
                {/*<div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url5">Adjuntar volante(opcional)</label>
                    <div className="input-group mb-3">
                        <div className="form-group campo_archivo">
                            <label htmlFor={"volante_pension"+index}>
                                { pensionItem.volante_pago.nombre? pensionItem.volante_pago.nombre : 'Subir archivo'}
                            </label>
                            <input 
                            onChange={(event) => {
                                saveFile(event, index)
                            }}
                            name="volante_pago"
                            type="file" 
                            className="form-control-file" 
                            id={"volante_pension"+index} style={{display: 'none'}} />
                        </div>
                    </div>
                </div>*/}
                <div className="col-md-6"></div>
                    <div className="col-md-6 d-lg-flex d-sm-block">
                    <div 
                    onClick={addPensionBtn}
                    className="m-lg-auto m-sm-2 text-center">
                        <div className="btn btn-outline-primary"
                        style={{ 
                            padding: 15,
                            borderColor: '#000080'
                        }}>
                            <span style={{fontSize: 18, cursor: 'pointer'}}>Agregar pension</span>
                            <span className="m-2">
                                <FontAwesomeIcon 
                                size="lg"
                                icon={faPlusCircle}/>
                            </span>
                        </div>
                    </div>
                    {index > 0 ? 
                    <div 
                    onClick={ () => deletePensionBtn(index) }
                    className="m-lg-auto m-sm-2 text-center">
                        <div className="btn btn-primary"
                        style={{ 
                            padding: 15,
                            backgroundColor: '#000080',
                            borderColor: '#000080'
                        }}>
                            <span style={{fontSize: 18, cursor: 'pointer'}}>Eliminar pension</span>
                            <span className="m-2">
                                <FontAwesomeIcon 
                                size="lg"
                                icon={faTimesCircle}/>
                            </span>
                        </div>
                    </div>
                    : null }
                </div>
            </div>
            )}
        </div>
        </Element>
    )
}
