import mobile_credito from '../assets/slider/mobile_credito.png';
export default function MobileCredito() {
    return (
        <div className="col-12 col-md-12 col-sm-12 d-block d-sm-block d-md-block d-lg-none text-center">
            <div className="mobile_credito_container">
                <h1 className="color_primario">
                    Dinos lo que necesitas, para <br/> 
                    saber  <b>¿Como podemos <br/> 
                    ayudarte?</b>
                </h1>
            </div>
            <img 
                style={{
                    width: '100%'
                }}
                src={mobile_credito}
                alt="Bancarizate right"
            />
            <p className="color_primario" style={{
                margin: 30,
                fontSize: 20
            }}>
                Selecciona la opcion que mejor se ajuste a tu necesidad
            </p>
        </div>
    )
}
