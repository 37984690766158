import React, { useState } from "react";
import { scroller } from "react-scroll";
import { updateWizardSteps } from '../store/wizardSlice';
import { updateLead, updateIngresos } from '../store/sectionsSlice';
import { useDispatch, useSelector } from "react-redux";
export default function Wizard() {
    const dispatch = useDispatch();
    const wizard_steps = useSelector(({ wizard }) => wizard.wizard_steps);
    const ingresos = useSelector(({ sections }) => sections.ingresos);
    const goNextStep = (offset = 0) => {
        const lastItem = wizard_steps[wizard_steps.length - 1];
        if(lastItem === 'stepFinal'){
            dispatch(updateLead());
            return;
        }
        scroller.scrollTo(lastItem, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: offset
        })
        if(lastItem === 'stepThree' ){
            dispatch(updateWizardSteps('stepOrigen'));
        }
        if(lastItem === 'stepFive'){
            dispatch(updateWizardSteps('stepFinal'));
        }
    }
    const selectDeuda = (response = false) => {
        if(response){
            dispatch(updateWizardSteps('stepFive'))
        }else{
            dispatch(updateWizardSteps('stepFinal'))
        }
    }
    const [count, setCount] = useState(0);
    const wizardIngresos = () => {
        var numberIngresos = ingresos.length;
        var slug = ingresos[count];
        scroller.scrollTo(slug, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: 0
        })
        if(count === numberIngresos){
            dispatch(updateWizardSteps('stepFour'))
        }else{
            setCount(count + 1);
            dispatch(updateIngresos());
        }
    }
    const getBtnTitle = () => {
        const lastItem = wizard_steps[wizard_steps.length - 1];
        switch (lastItem) {
            case 'stepOne':
                return 'Haz click aqui para empezar?';
            case 'stepTwo':
                return 'Ahora queremos saber mas de ti!';
            case 'stepThree':
                return 'Cuentanos de tus ingresos!';
            case 'stepFive':
                return 'Cuentanos de tus deudas!';
            case 'stepFinal':
                return 'Enviar solicitud';
            default:
                return 'Haz click aqui para empezar?'
        }
    }
    const continuarIngresos = () => {
        return (<>
            <div 
            className={`row m-4 ${wizard_steps[wizard_steps.length - 1] === 'stepOrigen' ? "" : "d-none"}`}
            >
                <div className="col-12 m-auto d-flex justify-content-center">
                    <button
                    onClick={ () => wizardIngresos() }
                    className="btn btn-primary"
                    style={{ 
                        padding: 15,
                        backgroundColor: '#000080',
                        borderColor: '#000080'
                    }}>
                        Continuar
                    </button>
                </div>
            </div>
        </>)
    }
    const yesNoRow = () => {
        return (<>
            <div 
            className={`row m-0 ${wizard_steps[wizard_steps.length - 1] === 'stepFour' ? "" : "d-none"}`}
            >
                <div 
                style={{
                    flexDirection: 'column'
                }}
                className="col-12 m-auto d-flex justify-content-center">
                    <h4
                    style={{
                        margin: 'auto',
                        padding: '15px 0'
                    }}
                    className="color_primario"
                    ><b>¿Tiene Deudas Vencidas o Castigadas?</b></h4>
                    <div style={{
                        display: 'flex',
                        margin: 'auto'
                    }}>
                        <button
                        onClick={ () => selectDeuda(true) } 
                        style={{
                            margin: '0 20px', 
                            padding: '5px 30px',
                            backgroundColor: '#000080',
                            borderColor: '#000080'
                        }}
                        className="btn btn-primary">Si</button>
                        <button 
                        onClick={ () => selectDeuda(false) } 
                        style={{
                            margin: '0 20px', 
                            padding: '5px 30px',
                            backgroundColor: '#000080',
                            borderColor: '#000080'
                        }}
                        className="btn btn-primary">No</button>
                    </div>
                </div>
            </div>
        </>)
    }
    const buttonRow = () => {
        return (<>
            <div 
            className={`row m-4 ${wizard_steps[wizard_steps.length - 1] === 'stepOrigen' ||  wizard_steps[wizard_steps.length - 1] === 'stepFour' ? "d-none" : ""}`}
            >
                <div className="col-12 m-auto d-flex justify-content-center">
                    <button
                    onClick={ () => goNextStep() }
                    className="btn btn-primary"
                    style={{ 
                        padding: 15,
                        backgroundColor: '#000080',
                        borderColor: '#000080'
                    }}>
                        {getBtnTitle()}
                    </button>
                </div>
            </div>
        </>)
    }
    return (
        <footer className="footer_wizard">
            <div className="container">
                { yesNoRow() }
                { continuarIngresos() } 
                { buttonRow() }
            </div>
        </footer>
    )
}
