import right from '../assets/slider/right.png';
import left from '../assets/slider/left.png';
export default function DiagonalBox() {
    return (
        <div className="diagonal-box">
            <div className="content">
                <div className="row">
                    <div className="col-lg-1 col-md-2 col-sm-2 col-2">
                        <img
                        style={{
                            width: '50px'
                        }} 
                        src={left}
                        alt="Question mark 2"
                        />
                    </div>
                    <div className="col-lg-10 col-md-8 col-sm-8 col-8">
                        <p>¿Estas reportado en centrales de riesgo? ¿Tienes deudas con acreedores hostiles y te sientes secuestrado por tus acreedores? ¿Simplemente buscas un credito de libranza con la mejor tasa de interes en el mercado?</p>
                    </div>
                    <div className="col-lg-1 col-md-2 col-sm-2 col-2">
                        <img
                        style={{
                            width: '50px'
                        }} 
                        src={right}
                        alt="Question mark 1"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
