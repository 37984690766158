import right_side from '../assets/slider/right_side.png';
import line_left from '../assets/slider/line_left.png';
import FloatText from '../components/FloatText';
export default function Slider(){
    return (
        <div className="bg_slider">
            <div 
            className="container mb-5 pt-5">
                <div className="row">
                    <FloatText />
                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <img 
                        style={{
                            width: '100%'
                        }}
                        src={right_side}
                        alt="Bancarizate right"
                        />
                    </div>
                    <div className="col-lg-5 d-none d-sm-none d-md-none d-lg-block text-center color_primario">
                        <div
                        style={{
                            marginTop: '30%'
                        }}>
                            <h1
                            style={{
                                fontSize: 64,
                                lineHeight: 0.9
                            }}
                            ><b>¿Necesitas un <br/> Crédito de  <br/> Libranza?</b></h1>
                            <img
                            style={{
                                width: '100%'
                            }}
                            src={line_left}
                            alt="Bancarizate left"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}