import React from 'react'

export default function HeaderWP() {
    return (
        <>
        <div className="header-main">
            <div className="header-inner">
                <div className="logo">
                    <a href="/#">
                        <img 
                        src="https://www.bancarizate.co/wp-content/uploads/2020/09/Untitled-design-46.png" 
                        className="header_logo header-logo" 
                        alt="Bancarizate" 
                        />
                    </a>
                </div>
                <div className="header-navigation d-none d-sm-none d-md-none d-lg-block">
                    <ul className="nav">
                        <li className="menu-item"><a href="https://www.bancarizate.co/">Inicio</a></li>
                        <li className="menu-item active"><a href="./">Credito de libranza</a></li>
                        <li className="menu-item"><a href="https://www.bancarizate.co/trabaja-con-nosotros/">Trabaja con nosotros</a></li>
                        <li className="menu-item"><a href="https://www.bancarizate.co/nuestra-empresa/">Nuestra empresa</a></li>
                        <li className="menu-item"><a href="https://www.bancarizate.co/contacto/">Contacto</a></li>
                        <li className="menu-item"><a href="http://web.bancarizate.co/">App</a></li>
                        <li className="menu-item"><a href="http://bancarizate.website/simlib">Simulador</a></li>
                    </ul>
                </div>
                <div className="header-navigation d-block d-sm-block d-md-block d-lg-none">
                    <button className="navbar-toggler" 
                    type="button" 
                    data-toggle="collapse" 
                    data-target="#navbarNav" 
                    aria-controls="navbarNav" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </div>
        </div>
        <div className="collapse navbar-collapse d-lg-none" id="navbarNav">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" href="https://www.bancarizate.co/">Inicio</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="./">Credito de libranza</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="https://www.bancarizate.co/trabaja-con-nosotros/">Trabaja con nosotros</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="https://www.bancarizate.co/nuestra-empresa/">Nuestra empresa</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="https://www.bancarizate.co/contacto/">Contacto</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="http://web.bancarizate.co/">App</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="http://bancarizate.website/simlib">Simulador</a>
                </li>
            </ul>
        </div>
        </>
    )
}
