import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ReactNumeric from 'react-numeric';
import { useDispatch, useSelector } from "react-redux";
import { addSalario, setSalario, removeSalario } from '../store/sectionsSlice';
import { Element } from "react-scroll";

import InputPagadurias from "../components/InputPagadurias";
export default function Salarioform() {
    const dispatch = useDispatch();
    const salariosList = useSelector(({ sections }) => sections.salarios);
    const [moreSalarios, setMoreSalarios] = useState(false);
    const addSalarioBtn = () => {
        if(salariosList.length <= 2){
            dispatch(addSalario());
            setMoreSalarios(false)
        }else{
            setMoreSalarios(true)
        }
    }
    const deleteSalarioBtn = (index) => {
        dispatch(removeSalario(index));
    }
    const saveData = (event, index) => {
        var data = {
            index,
            field: event.target.name,
            value: event.target.value  
        }
        dispatch(setSalario(data));
    }
    const savePagaduria = (newValue, index) => {
        var data = {
            index,
            field: 'empresa',
            value: newValue   
        }
        dispatch(setSalario(data));
    }
    const savePrice = (event, index, value) => {
        var data = {
            index,
            field: event.target.name,
            value: value 
        }
        dispatch(setSalario(data));
    }
    /**const saveFile = (event, index) => {
        const { files } = event.target;
        const localImageUrl =  files[0];
        var data = {
            index,
            field: event.target.name,
            value: {
                file: localImageUrl,
                nombre: files[0].name
            }   
        }
        dispatch(setSalario(data));
    }**/
    return (
        <Element name="salario" className="element">
        <div className="container">
            {moreSalarios ?
            <div className="row m-4">
                <div className="col-md-12">
                    <div className="alert alert-danger" role="alert">
                        Maximo 3 salarios!
                    </div>
                </div>
            </div>
            : null}
            { salariosList.map((salarioItem, index) => 
                <div key={index} className="row m-4 ">
                    <div className="col-md-12 color_secundario">
                        <h1><b>Sobre tu Salario 
                            {index === 0 ? null : `# ${index+1}` }
                        </b></h1>
                        <hr className="divider_titulo" />
                    </div>
                    <div className="col-md-6">
                        <label 
                        className="color_primario"
                        htmlFor="basic-url5">Devengado</label>
                        <div className="input-group mb-3">
                            <ReactNumeric
                                id="basic-url5"
                                className="form-control" 
                                currencySymbol="$ "
                                minimumValue="0"
                                decimalCharacter=","
                                decimalPlaces="0"
                                digitGroupSeparator="."
                                name="devengado"
                                value={salarioItem.devengado} 
                                onChange={(event, value) => {
                                    savePrice(event, index, value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label 
                        className="color_primario"
                        htmlFor="basic-url6">Nombre de la empresa</label>
                        <div className="input-group mb-3">
                            <InputPagadurias 
                            value={salarioItem.empresa} 
                            onChangeLocal={
                                (event, { newValue }) => {
                                    savePagaduria(newValue, index);
                                }
                            }
                            name="empresa"
                            />
                            {/*<input 
                            value={salarioItem.empresa} 
                            onChange={(event) => {
                                saveData(event, index)
                            }}
                            name="empresa"
                            type="text" 
                            className="form-control" />*/}

                        </div>
                    </div>
                    <div className="col-md-6">
                        <label 
                        className="color_primario"
                        htmlFor="basic-url5">Fecha de vinculación</label>
                        <div className="input-group mb-3">
                            <input 
                            value={salarioItem.inicio_contrato} 
                            onChange={(event) => {
                                saveData(event, index)
                            }}
                            name="inicio_contrato"
                            type="date" 
                            className="form-control" 
                            id="basic-url5" 
                            aria-describedby="basic-addon3" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label 
                        className="color_primario"
                        htmlFor="basic-url6">Tipo contrato</label>
                        <div className="input-group mb-3">
                            <select
                            value={salarioItem.tipo_contrato}
                            name="tipo_contrato" 
                            onChange={(event) => {
                                saveData(event, index)
                            }}
                            className="form-control" 
                            >
                                <option value={null}>Seleccione...</option>
                                <option value={"Termino Fijo"}>Contrato Termino Fijo</option>
                                <option value={"Indefinido"}>Contrato Termino Indefinido</option>
                            </select>
                        </div>
                    </div>
                    {/*<div className="col-md-6">
                        <label 
                        className="color_primario"
                        htmlFor="basic-url5">Adjuntar Ultimo Volante(opcional)</label>
                        <div className="input-group mb-3">
                            <div className="form-group campo_archivo">
                                <label htmlFor={"volante"+index}>
                                    {'Subir archivo'}
                                </label>
                                <input 
                                type="file" 
                                className="form-control-file" 
                                id={"volante"+index} style={{display: 'none'}} />
                            </div>
                        </div>
                    </div>*/}
                    <div className="col-md-6"></div>
                    <div className="col-md-6 d-lg-flex d-sm-block">
                        <div 
                        onClick={() => addSalarioBtn()}
                        className="m-lg-auto m-sm-2 text-center">
                            <div className="btn btn-outline-primary"
                            style={{ 
                                padding: 15,
                                borderColor: '#000080'
                            }}>
                                <span style={{fontSize: 18, cursor: 'pointer'}}>Agregar salario</span>
                                <span className="m-2">
                                    <FontAwesomeIcon 
                                    size="lg"
                                    icon={faPlusCircle}/>
                                </span>
                            </div>
                        </div>
                        {index > 0 ? 
                        <div 
                        onClick={() => deleteSalarioBtn(index)}
                        className="m-lg-auto m-sm-2 text-center">
                            <div className="btn btn-primary"
                            style={{ 
                                padding: 15,
                                backgroundColor: '#000080',
                                borderColor: '#000080'
                            }}>
                                <span style={{fontSize: 18, cursor: 'pointer'}}>Eliminar salario</span>
                                <span className="m-2">
                                    <FontAwesomeIcon 
                                    size="lg"
                                    icon={faTimesCircle}/>
                                </span>
                            </div>
                        </div>
                        : null }
                    </div>
                </div>
            )}
        </div>
        </Element>
    )
}
