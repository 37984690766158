import bitrix_fields from '../assets/bitrix_fields.json';
var object_deudas = {
    /**Tienes deudas */
    "UF_CRM_1615859865783":"",
    "UF_CRM_1615859910798":"",
    "UF_CRM_1615859952096":"",
    /**Acreencia 1 */
    "UF_CRM_1615860024750":"",
    "UF_CRM_1615860051463":"",
    "UF_CRM_1615860095712":"",
    "UF_CRM_1615860115820":"",
    /**Acreencia 2 */
    "UF_CRM_1615860150454":"",
    "UF_CRM_1615860164531":"",
    "UF_CRM_1615860177507":"",
    "UF_CRM_1615860192810":"",
    /**Acreencia 3 */
    "UF_CRM_1615860218845":"",
    "UF_CRM_1615860235936":"",
    "UF_CRM_1615860246627":"",
    "UF_CRM_1615860265773":""
}
var object_ingresos = {
    /**Ocupacion 1 */
    "UF_CRM_1615852605827":"",
    "UF_CRM_1615852646468":"",
    "UF_CRM_1615852698371":"",
    "UF_CRM_1615858580551":"",
    "UF_CRM_1615858614312":"",
    "UF_CRM_1615858636355":"",
    /**Ocupacion 2 */
    "UF_CRM_1615858767783":"",
    "UF_CRM_1615858788346":"",
    "UF_CRM_1615858814201":"",
    "UF_CRM_1615858859925":"",
    "UF_CRM_1615858907083":"",
    "UF_CRM_1615858959907":"",
    /**Ocupacion 3 */
    "UF_CRM_1615859048893":"",
    "UF_CRM_1615859064530":"",
    "UF_CRM_1615859080298":"",
    "UF_CRM_1615859093568":"",
    "UF_CRM_1615859128623":"",
    "UF_CRM_1615859148156":"",
    /**Ocupacion 4 */
    "UF_CRM_1615859233008":"",
    "UF_CRM_1615859256515":"",
    "UF_CRM_1615859279717":"",
    "UF_CRM_1615859296077":"",
    "UF_CRM_1615859322624":"",
    "UF_CRM_1615859339411":""
}
const contact = {
    get_values_selected(field, value){
        var fields_list = bitrix_fields.result;
        var items_list = fields_list[field].items;
        var item = items_list.find( item => item.VALUE === value);
        return item ? item.ID : '';
    },
    process_ingresos(global_array){
        //Origen ingresos
        global_array.forEach((item, index) => {
            switch (index) {
                case 0:
                    object_ingresos = { ...object_ingresos, 
                        "UF_CRM_1615852605827": this.get_values_selected("UF_CRM_1615852605827", item.tipo),
                        "UF_CRM_1615852646468": item.devengado,
                        "UF_CRM_1615852698371": item.tipo === 'Activo' ? item.empresa: item.pagaduria,
                        "UF_CRM_1615858580551": item.tipo === 'Activo' ? item.inicio_contrato: "",
                        "UF_CRM_1615858614312": item.tipo === 'Activo' ? this.get_values_selected("UF_CRM_1615858614312", item.tipo_contrato) : "",
                        "UF_CRM_1615858636355": "",
                    };
                break;
                case 1:
                    object_ingresos = { ...object_ingresos, 
                        "UF_CRM_1615858767783": this.get_values_selected("UF_CRM_1615858767783", item.tipo),
                        "UF_CRM_1615858788346": item.devengado,
                        "UF_CRM_1615858814201": item.tipo === 'Activo' ? item.empresa: item.pagaduria,
                        "UF_CRM_1615858859925": item.tipo === 'Activo' ? item.inicio_contrato: "",
                        "UF_CRM_1615858907083": item.tipo === 'Activo' ? this.get_values_selected("UF_CRM_1615858907083", item.tipo_contrato) : "",
                        "UF_CRM_1615858959907":"",
                    };
                break;
                case 2:
                    object_ingresos = { ...object_ingresos, 
                        "UF_CRM_1615859048893": this.get_values_selected("UF_CRM_1615859048893", item.tipo),
                        "UF_CRM_1615859064530": item.devengado,
                        "UF_CRM_1615859080298": item.tipo === 'Activo' ? item.empresa: item.pagaduria,
                        "UF_CRM_1615859093568": item.tipo === 'Activo' ? item.inicio_contrato: "",
                        "UF_CRM_1615859128623": item.tipo === 'Activo' ? this.get_values_selected("UF_CRM_1615859128623", item.tipo_contrato) : "",
                        "UF_CRM_1615859148156":"",
                    };
                break;
                case 3:
                    object_ingresos = { ...object_ingresos, 
                        "UF_CRM_1615859233008": this.get_values_selected("UF_CRM_1615859233008", item.tipo),
                        "UF_CRM_1615859256515": item.devengado,
                        "UF_CRM_1615859279717": item.tipo === 'Activo' ? item.empresa: item.pagaduria,
                        "UF_CRM_1615859296077": item.tipo === 'Activo' ? item.inicio_contrato: "",
                        "UF_CRM_1615859322624": item.tipo === 'Activo' ? this.get_values_selected("UF_CRM_1615859322624", item.tipo_contrato) : "",
                        "UF_CRM_1615859339411": "",
                    };
                break;
                default:
                    break;
            }
        })
        return object_ingresos;
    },
    process_deudas(deudas){
        deudas.forEach((item, index) => {
            switch (index) {
                case 0:
                    object_deudas = { ...object_deudas, 
                        "UF_CRM_1615860024750": item.valor_deuda,
                        "UF_CRM_1615860051463": item.fecha,
                        "UF_CRM_1615860095712":"",
                        "UF_CRM_1615860115820": item.quien_le_debes,
                    }; 
                break;
                case 1:
                    object_deudas = { ...object_deudas, 
                        "UF_CRM_1615860150454":item.valor_deuda,
                        "UF_CRM_1615860164531":item.fecha,
                        "UF_CRM_1615860177507":"",
                        "UF_CRM_1615860192810":item.quien_le_debes,
                    };
                break;
                case 2:
                    object_deudas = { ...object_deudas, 
                        "UF_CRM_1615860218845": item.valor_deuda,
                        "UF_CRM_1615860235936": item.fecha,
                        "UF_CRM_1615860246627": "",
                        "UF_CRM_1615860265773": item.quien_le_debes
                    };
                break;
                default:
                break;
            }
        })
        return object_deudas;
    }
}
export default contact;