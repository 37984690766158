import "./App.scss";

import Landing from './layouts/Landing';
//import Sidebar from './layouts/Sidebar';

export default function App(){
    
    return (
        <div className="App" style={{
            overflow: 'hidden'
        }}>
            <Landing />
        </div>
    )
}
