import { createSlice } from '@reduxjs/toolkit';
import tipos_credito from '../assets/tipos_credito.json';
import { fromJS } from 'immutable';
import webhooks from '../helpers/webhooks';
import contact from '../helpers/contact';
import { formatISO } from 'date-fns';

export const createLead = (data = {}) => async (dispatch) => {
    dispatch(updateContacto(data));
    //dispatch(updateBitrix(368));
    if(data.fecha_nacimiento){
        var format_birth = formatISO(new Date(data.fecha_nacimiento));
    }
    try {
        //Parsing data
        var data_multiple = {
            contact: {
                "NAME": data.nombres, 
                "SECOND_NAME": "", 
                "LAST_NAME": data.apellidos, 
                "OPENED": "Y",
                "TYPE_ID": "CLIENT",
                "SOURCE_ID:": "SELF",
                "PHONE" : [
                    {
                        "VALUE": data.celular,
                        "VALUE_TYPE": "WORK"
                    }
                ],
                "UF_CRM_1615851482777": data.politica,
                "UF_CRM_1615851515031": data.cedula,
                "UF_CRM_1615851790166" : data.ciudad,
                "BIRTHDATE": data.fecha_nacimiento ? format_birth : null
            },
            lead: {
                "TITLE": data.nombres+" "+data.apellidos, 
                "NAME": data.nombres, 
                "SECOND_NAME": "", 
                "LAST_NAME": data.apellidos, 
                "STATUS_ID": "NEW",
                "PHONE": data.celular,
                "EMAIL:": data.email
            }
        };
        const result = await webhooks.sendBitrix_lead(data_multiple);
        dispatch(updateBitrix(result.contact_id));
    } catch (error) {
        return false;
    }
}
export const updateIngresos = (data = {}) => async (dispatch, getState) => {
    var global_array = [];
    var localStates = getState().sections;
    if(localStates.ingresos.includes('salario')){
        global_array = [...global_array, ...localStates.salarios];
    }
    if(localStates.ingresos.includes('pension')){
        global_array = [...global_array, ...localStates.pensiones];
    }
    if(localStates.ingresos.includes('independiente')){
        global_array = [...global_array, localStates.independiente];
    }
    if(localStates.ingresos.includes('servicios')){
        global_array = [...global_array, localStates.prestacion];
    }
    //Origen ingresos
    var bitrix_ingresos = contact.process_ingresos(global_array);
    try {
        const result = await webhooks.sendBitrix_contact_update(localStates.bitrix_id, bitrix_ingresos);
        console.log(result);
    } catch (error) {
        return false;
    }
}

export const updateLead = (data = {}) => async (dispatch, getState) => {
    dispatch(updateLoaderEnviando(true));
    var localStates = getState().sections;
    var wizardStates = getState().wizard;
    if(wizardStates.wizard_steps.includes('stepFive')){
        var total = 0;
        for(var i in localStates.deudas) { total += localStates.deudas[i].valor_deuda; }
        var bitrix_deudas = contact.process_deudas(localStates.deudas);
        bitrix_deudas = { ...bitrix_deudas, 
            "UF_CRM_1615859865783": "122",
            "UF_CRM_1615859910798": localStates.deudas.length,
            "UF_CRM_1615859952096": total
        };
    }
    try {
        const result = await webhooks.sendBitrix_contact_update(localStates.bitrix_id, bitrix_deudas);
        console.log(result);
        //alert("Actualizo Deudas o finalizo");
        //dispatch(updateLoaderEnviando(false));
        window.location.href = "https://www.bancarizate.co/gracias-por-tu-solicitud/";
    } catch (error) {
        return false;
    }
}
const sectionsSlice = createSlice({
    name: 'sections',
    initialState: {
        bitrix_id: null,
        tipo_credito: null,
        titulo_credito: null,
        contenido_credito: null,
        loader_enviando: false,
        contacto_form: {
            nombres: "",
            apellidos: "",
            celular: "",
            email: "",
            cedula: "",
            ciudad: "",
            genero: "",
            fecha_nacimiento: "",
            politica: false
        },
        modal_ingresos: {
            open: false,
            titulo: "",
            contenido: ""
        },
        ingresos: [],
        salarios: [
            {
                tipo: "Activo",
                devengado: 0,
                empresa: "",
                inicio_contrato: "",
                tipo_contrato: "",
                volante_pago: {
                    file: "",
                    nombre: ""
                }
            }
        ],
        pensiones: [
            {
                tipo: "Pensionado",
                devengado: 0,
                pagaduria: "",
                volante_pago: {
                    file: "",
                    nombre: ""
                }
            }
        ],
        deudas: [
            {
                valor_deuda: 0,
                fecha: "",
                quien_le_debes: ""
            }
        ],
        prestacion: {
            tipo: "Prestador de Servicios",
            devengado: 0,
            pagaduria: "",
            volante_pago: {
                file: "",
                nombre: ""
            }
        },
        independiente: {
            tipo: "Independiente",
            devengado: 0,
            pagaduria: "",
            volante_pago: {
                file: "",
                nombre: ""
            }
        }
    },
    reducers: {
        updateLoaderEnviando(state, action){
            state.loader_enviando = action.payload;
        },
        updateContacto(state, action){
            state.contacto_form = { ...state.contacto_form, ...action.payload };
        },
        updatePrestacion(state, action){
            state.prestacion = { ...state.prestacion, 
                [action.payload.field] : action.payload.value
            };
        },
        updateIndependiente(state, action){
            state.independiente = { ...state.independiente, 
                [action.payload.field] : action.payload.value
            };
        },
        selectIngresos(state, action){
            if(state.ingresos.includes(action.payload)){
                var filtered = state.ingresos.filter(function(value, index, arr){ 
                    return value !== action.payload;
                })
                state.ingresos = filtered;
            }else{
                state.ingresos.push(action.payload);
            }
        },
        selectTipoCredito(state, action) {
            state.tipo_credito = action.payload;
            var creditosList = tipos_credito.creditos;
            var found = creditosList.find(element => element.slug === action.payload);
            state.titulo_credito = found.titulo;
            state.contenido_credito = found.contenido;
        },
        addSalario(state, action){
            state.salarios.push({
                ...state.salarios[0], 
                devengado: 0,
                empresa: "",
                inicio_contrato: "",
                tipo_contrato: "",
                volante_pago: {
                    file: "",
                    nombre: ""
                }
            });
        },
        removeSalario(state, action){
            state.salarios = [...state.salarios.slice(0, action.payload), ...state.salarios.slice(action.payload + 1)];
        },
        setSalario(state, action){
            var {index, field, value} = action.payload;
            var immutableSalarios = fromJS(state.salarios);
            var objectSalario = immutableSalarios.get(index);
            var newSalario = objectSalario.set(field, value);
            var newArray = immutableSalarios.set(index, { ...newSalario.toJS() });
            state.salarios = newArray.toJS();
        },
        addPension(state, action){
            state.pensiones.push({
                ...state.pensiones[0], 
                devengado: 0,
                pagaduria: "",
                volante_pago: {
                    file: "",
                    nombre: ""
                }
            });
        },
        removePension(state, action){
            state.pensiones = [...state.pensiones.slice(0, action.payload), ...state.pensiones.slice(action.payload + 1)];
        },
        setPension(state, action){
            var {index, field, value} = action.payload;
            var immutablePensiones = fromJS(state.pensiones);
            var objectPension = immutablePensiones.get(index);
            var newPension = objectPension.set(field, value);
            var newArray = immutablePensiones.set(index, { ...newPension.toJS() });
            state.pensiones = newArray.toJS();
        },
        addDeuda(state, action){
            state.deudas.push({...state.deudas[0], valor_deuda: 0});
        },
        removeDeuda(state, action){
            state.deudas = [...state.deudas.slice(0, action.payload), ...state.deudas.slice(action.payload + 1)];
        },
        setDeuda(state, action){
            var {index, field, value} = action.payload;
            var immutableDeudas = fromJS(state.deudas);
            var objectDeuda = immutableDeudas.get(index);
            var newDeuda = objectDeuda.set(field, value);
            var newArray = immutableDeudas.set(index, { ...newDeuda.toJS() });
            state.deudas = newArray.toJS();
        },
        openIngresos(state, action){
            var ingresosList = tipos_credito.ingresos;
            var found = ingresosList.find(element => element.slug === action.payload.slug);
            var info = {
                open: action.payload.open,
                ...found
            }
            state.modal_ingresos = { ...info };
        },
        updateBitrix(state, action){
            state.bitrix_id = action.payload;
        }
    },
})
export const { 
    updateContacto,
    selectIngresos,
    selectTipoCredito,
    addSalario,
    removeSalario,
    addPension,
    addDeuda,
    removePension,
    removeDeuda,
    openIngresos,
    updateBitrix,
    setSalario,
    setPension,
    setDeuda,
    updatePrestacion,
    updateIndependiente,
    updateLoaderEnviando
} = sectionsSlice.actions
export default sectionsSlice.reducer