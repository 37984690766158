import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Element } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import ReactNumeric from 'react-numeric';
import { addDeuda, setDeuda, removeDeuda } from '../store/sectionsSlice';
export default function Deudasform() {
    const dispatch = useDispatch();
    const contacto_form = useSelector(({ sections }) => sections.contacto_form);
    const deudasList = useSelector(({ sections }) => sections.deudas);
    const [moreDeudas, setMoreDeudas] = useState(false);
    const addDeudasBtn = () => {
        if(deudasList.length <= 2){
            dispatch(addDeuda());
            setMoreDeudas(false)
        }else{
            setMoreDeudas(true)
        }
    }
    const deleteDeudasBtn = (index) => {
        dispatch(removeDeuda(index));
    }
    const saveData = (event, index) => {
        var data = {
            index,
            field: event.target.name,
            value: event.target.value  
        }
        dispatch(setDeuda(data));
    }
    const savePrice = (event, index, value) => {
        var data = {
            index,
            field: event.target.name,
            value: value 
        }
        dispatch(setDeuda(data));
    }
    return (
        <Element name="stepFive" className="element">
        <div className="container">
            <div className="titulo_container color_primario">
                <h1>
                    <b>Muy bien {contacto_form.nombres} {contacto_form.apellidos}, sobre tus deudas vencidas o castigadas</b>
                </h1>
                <p>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet
                </p>
            </div>
            {moreDeudas ?
            <div className="row m-4">
                <div className="col-md-12">
                    <div className="alert alert-danger" role="alert">
                        Maximo 3 deudas!
                    </div>
                </div>
            </div>
            : null}
            { deudasList.map((deudaItem, index) => 
            <div key={index} className="row m-4">
                <div className="col-md-12 color_secundario">
                    <h1><b>Sobre tus acreencia # {index + 1}</b></h1>
                    <hr className="divider_titulo" />
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url5">Valor inicial deuda</label>
                    <div className="input-group mb-3">
                        <ReactNumeric
                            id="basic-url5"
                            className="form-control" 
                            currencySymbol="$ "
                            minimumValue="0"
                            decimalCharacter=","
                            decimalPlaces="0"
                            digitGroupSeparator="."
                            name="valor_deuda"
                            value={deudaItem.valor_deuda} 
                            onChange={(event, value) => {
                                savePrice(event, index, value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url6">Fecha Aproximada (Mes y Año)</label>
                    <div className="input-group mb-3">
                        <input 
                        value={deudaItem.fecha} 
                        onChange={(event) => {
                            saveData(event, index)
                        }}
                        name="fecha"
                        type="month" 
                        className="form-control" 
                        id="basic-url6" 
                        aria-describedby="basic-addon3" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url5">A quien le debes?</label>
                    <div className="input-group mb-3">
                        <input 
                        value={deudaItem.quien_le_debes} 
                        onChange={(event) => {
                            saveData(event, index)
                        }}
                        name="quien_le_debes"
                        type="text" 
                        className="form-control" 
                        id="basic-url5" 
                        aria-describedby="basic-addon3" />
                    </div>
                </div>
                <div className="col-md-6 d-lg-flex d-sm-block">
                    <div 
                    onClick={addDeudasBtn}
                    className="m-lg-auto m-sm-2 text-center">
                        <div className="btn btn-outline-primary"
                        style={{ 
                            padding: 15,
                            borderColor: '#000080'
                        }}>
                            <span style={{fontSize: 18, cursor: 'pointer'}}>Agregar deuda</span>
                            <span className="m-2">
                                <FontAwesomeIcon 
                                size="lg"
                                icon={faPlusCircle}/>
                            </span>
                        </div>
                    </div>
                    {index > 0 ? 
                    <div 
                    onClick={() => deleteDeudasBtn(index)}
                    className="m-lg-auto m-sm-2 text-center">
                        <div className="btn btn-primary"
                        style={{ 
                            padding: 15,
                            backgroundColor: '#000080',
                            borderColor: '#000080'
                        }}>
                            <span style={{fontSize: 18, cursor: 'pointer'}}>Eliminar deuda</span>
                            <span className="m-2">
                                <FontAwesomeIcon 
                                size="lg"
                                icon={faTimesCircle}/>
                            </span>
                        </div>
                    </div>
                    : null }
                </div>
            </div>
            )}
        </div>
        </Element>
    )
}
