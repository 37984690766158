import line_left from '../assets/slider/line_left.png';
export default function FloatText() {
    return (
        <div
        className="float_text d-lg-none d-md-block d-sm-block">
            <h1
            className="color_primario"
            ><b>¿Necesitas un <br/> Crédito de  <br/> Libranza?</b></h1>
            <img
            style={{
                width: '100%'
            }}
            src={line_left}
            alt="Bancarizate left"
            />
        </div>
    )
}
