import { configureStore } from '@reduxjs/toolkit';

import wizardSlice from './wizardSlice';
import sectionsSlice from './sectionsSlice';

const store = configureStore({
    reducer: {
        wizard: wizardSlice,
        sections: sectionsSlice,
    },
    devTools: true
})

export default store;