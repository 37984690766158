export default function ModalRequisitos() {
    return (
        <div 
        id="requisitosModal"
        className="modal fade">
            <div className="modal-dialog">
                <div className="modal-content">
                    <a 
                    href={() => false}
                    data-dismiss="modal"
                    className="close-modal">x</a>
                    <h2>Requisitos</h2>
                    <div className="space-md"></div>
                    <p className="m-0">
                        <h2>Pensionados</h2>
                        Para iniciar tu solicitud de crédito debes tener a la mano los 2 últimos desprendibles de pago de tu pensión, copia ampliada de tu cédula y autorización de consulta en centrales de riesgo. (Este último documento será entregado por tu asesor comercial)
                        *Recuerda que podemos comprar de una (1) a cuatro (4) carteras máximo por cliente.
                        <h2>Policías</h2>
                        Para iniciar tu solicitud de crédito debes tener a la mano los 2 últimos desprendibles de pago de tu nómina, copia ampliada de tu cédula y autorización de consulta en centrales de riesgo. (Este último documento será entregado por tu asesor comercial)
                        *Recuerda que podemos comprar de una (1) a tres (3) carteras máximo por cliente.
                        <h2>Empleado Público</h2>
                        Para iniciar tu solicitud de crédito debes tener a la mano los 2 últimos desprendibles de pago de tu nómina, copia ampliada de tu cédula y autorización de consulta en centrales de riesgo. (Este último documento será entregado por tu asesor comercial)
                        *Recuerda que podemos comprar de una (1) a cuatro (3) carteras máximo por cliente.
                        <h2>Docente Público</h2>
                        Para iniciar tu solicitud de crédito debes tener a la mano los 2 últimos desprendibles de pago de tu nómina, copia ampliada de tu cédula y autorización de consulta en centrales de riesgo. (Este último documento será entregado por tu asesor comercial)
                        *Tu contrato debe ser nombrado en propiedad
                        *Recuerda que podemos comprar de una (1) a tres (3) carteras máximo por cliente.
                    </p>
                </div>
            </div>
        </div>
    );
}
