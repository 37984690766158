import React from 'react'
import { useSelector } from "react-redux";
export default function MainLoader() {
    const contacto_form = useSelector(({ sections }) => sections.contacto_form);
    return (
        <div className="loader">
            <div className="container">
                <div className="titulo_container color_primario">
                    <h1>
                        <b>Excelente {contacto_form.nombres} {contacto_form.apellidos}, cada vez estas mas cerca del credito que necesitas</b>
                    </h1>
                    <div className="text-center" style={{
                        paddingTop: 15
                    }}>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
    )
}
