import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { createLead } from '../store/sectionsSlice';
import { updateWizardSteps } from '../store/wizardSlice';
import { useDispatch, useSelector } from "react-redux";
import InputMask from 'react-input-mask';
import ReactNumeric from 'react-numeric';

import ModalPrivacidad from '../components/ModalPrivacidad';
import InputCiudades from "../components/InputCiudades";


export default function Contactoform() {
    const dispatch = useDispatch();
    const bitrix_id = useSelector(({ sections }) => sections.bitrix_id);
    const [validemail, setValidemail] = useState(false);
    const [validform, setValidform] = useState(false);
    const [form, setForm] = useState({
        nombres: "",
        nombresError: false,
        apellidos: "",
        apellidosError: false,
        celular: "",
        celularError: false,
        email: "",
        cedula: "",
        cedulaError: false,
        fecha_nacimiento: "",
        ciudad: "",
        genero: "",
        fecha_nacimientoError: false,
        politica: false
    })
    const saveData = (event) => {
        var tieneError = event.target.value !== '' && event.target.value.length < 5;
        setForm({
            ...form,
            [event.target.name] : event.target.value,
            [event.target.name+'Error'] : tieneError
        })
    }
    const saveCedula = (val) => {
        setForm({
            ...form,
            cedula : val,
            cedulaError : false
        })
    }
    const saveCelular = (event) => {
        var tieneError = event.target.value !== '' && event.target.value.length < 12;
        setForm({
            ...form,
            [event.target.name] : event.target.value,
            [event.target.name+'Error'] : tieneError
        })
    }
    const saveEmail = (e) => {
        setForm({
            ...form,
            email : e.target.value
        })
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(e.target.value)) {
            setValidemail(true);
        }else{
            setValidemail(false);   
        }
    }
    const savePolitica = () => {
        setForm({
            ...form,
            politica : !form.politica
        })
    }
    useEffect(() => {
        var {
            nombresError, 
            apellidosError, 
            celularError, 
            politica } = form;
        if(nombresError || apellidosError || celularError){
            setValidform(true);
        }else{
            setValidform(false); 
        }
        if(politica && !validform && !bitrix_id){
            dispatch(updateWizardSteps('stepThree'));
            dispatch(createLead({...form}));
        }
    }, [form, bitrix_id, dispatch, validform]);
    return (
        <Element name="stepTwo" className="element">
        <div className="container">
            <div className="titulo_container color_primario">
                <h1>
                    <b>Tus datos basicos y de Contacto</b>
                </h1>
                <p>
                    Solo te contactaremos para tratar temas acerca de la forma en que podemos ayudarte
                </p>
            </div>
            <hr />
            {validform ?
            <div className="row m-4">
                <div className="col-md-12">
                    <div className="alert alert-danger" role="alert">
                       Falta 1 o mas campos obligatorios!
                    </div>
                </div>
            </div>
            : null}
            <div className="row m-4">
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url">Nombres</label>
                    <div className="input-group mb-3">
                        <input
                        value={form.nombres} 
                        onChange={saveData} 
                        type="text" 
                        className={`form-control ${form.nombresError ? "is-invalid" : ""}`}   
                        id="basic-url" 
                        name="nombres"
                        aria-describedby="basic-addon3" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url2">Apellidos</label>
                    <div className="input-group mb-3">
                        <input 
                        value={form.apellidos} 
                        onChange={saveData} 
                        name="apellidos"
                        type="text" 
                        className={`form-control ${form.apellidosError ? "is-invalid" : ""}`} 
                        id="basic-url2" 
                        aria-describedby="basic-addon3" />
                    </div>
                </div>
            </div>
            <div className="row m-4">
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url3">Numero celular</label>
                    <div className="input-group mb-3">
                        <InputMask 
                            type="text"
                            mask={"999 999 99 99"}
                            maskChar={null}
                            onChange={saveCelular}
                            value={form.celular}
                            name='celular'
                            className={`form-control ${form.celularError ? "is-invalid" : ""}`}   
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url4">Correo Electronico(opcional)</label>
                    <div className="input-group mb-3 has-validation">
                        <input 
                        value={form.email} 
                        onChange={saveEmail} 
                        name="email"
                        type="text" 
                        className={`form-control ${validemail ? "is-invalid" : ""}`}  
                        id="basic-url4" 
                        aria-describedby="basic-addon3" />
                        { validemail ?
                            <div className="invalid-feedback d-block">
                                Este email no es valido
                            </div>
                        : null}
                    </div>
                </div>
            </div>
            <div className="row m-4">
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url5">Cedula numero(opcional)</label>
                    <div className="input-group mb-3">
                        <ReactNumeric
                            className="form-control" 
                            currencySymbol=""
                            minimumValue="0"
                            decimalCharacter=","
                            decimalPlaces="0"
                            digitGroupSeparator="."
                            name="cedula"
                            value={form.cedula}  
                            onChange={(event, value) => {
                                saveCedula(value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url6">Fecha de Nacimiento</label>
                    <div className="input-group mb-3">
                        <input 
                        value={form.fecha_nacimiento} 
                        onChange={saveData} 
                        name="fecha_nacimiento"
                        type="date" 
                        className="form-control" 
                        id="basic-url6" 
                        aria-describedby="basic-addon3" />
                    </div>
                </div>
            </div>
            <div className="row m-4">
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url5">Ciudad (opcional)</label>
                    <div className="input-group mb-3">
                        <InputCiudades 

                        />
                        {/* <input 
                        value={form.ciudad}
                        onChange={saveData} 
                        name="ocupacion"
                        type="text" 
                        className="form-control"
                        id="basic-url2" 
                        aria-describedby="basic-addon3" />*/}
                    </div>
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url6">Genero</label>
                    <div className="input-group mb-3">
                        <select
                        value={form.genero}
                        name="genero" 
                        onChange={saveData}
                        className="form-control" 
                        >
                            <option value={null}>Seleccione...</option>
                            <option value={"Masculino"}>Masculino</option>
                            <option value={"Femenino"}>Femenino</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row m-4">
                <div className="col-md-12">
                    <div className="custom-control custom-checkbox">
                    <input 
                    defaultChecked={form.politica}
                    onChange={savePolitica} 
                    name="politica"
                    type="checkbox" 
                    className="custom-control-input" 
                    id="customCheck1" />
                    <label className="custom-control-label" htmlFor="customCheck1">Declaro que he leido y acepto los <a 
                        onClick={(e) => e.preventDefault }
                        data-toggle="modal" 
                        data-target="#privacidadModal"
                        style={{cursor: 'pointer', textDecoration: 'underline'}}
                        href={() => false}
                        className="color_primario" 
                        >terminos y condiciones</a> de la politica de tratamiento de datos de Upgrade Crediticio</label>
                    </div>
                </div>
            </div>
        </div>
        <ModalPrivacidad />
        </Element>
    )
}
