import ReactNumeric from 'react-numeric';
import { updatePrestacion } from '../store/sectionsSlice';
import { useDispatch, useSelector } from "react-redux";
import { Element } from "react-scroll";
export default function Serviciosform() {
    const dispatch = useDispatch();
    const prestacionForm = useSelector(({ sections }) => sections.prestacion);
    const saveData = (event) => {
        dispatch(updatePrestacion({
            field: event.target.name,
            value: event.target.value
        }));
    }
    const savePrice = (event, value) => {
        dispatch(updatePrestacion({
            field: event.target.name,
            value
        }));
    }
    /**const saveFile = (event) => {
        const { files } = event.target;
        const localImageUrl =  window.URL.createObjectURL(files[0]);
        var data = {
            field: event.target.name,
            value: {
                file: localImageUrl,
                nombre: files[0].name
            }   
        }
        dispatch(updatePrestacion(data));
    }**/
    return (
        <Element name="servicios" className="element">
        <div className="container">
            <div className="row m-4">
                <div className="col-md-12 color_secundario">
                    <h1><b>Sobre tu prestacion de servicio</b></h1>
                    <hr className="divider_titulo" />
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url5">Devengado</label>
                    <div className="input-group mb-3">
                        <ReactNumeric
                            id="basic-url5"
                            className="form-control" 
                            currencySymbol="$ "
                            minimumValue="0"
                            decimalCharacter=","
                            decimalPlaces="0"
                            digitGroupSeparator="."
                            value={prestacionForm.devengado}
                            name="devengado"
                            onChange={(event, value) => {
                                savePrice(event, value)
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url6">Actividad:</label>
                    <div className="input-group mb-3">
                        <input 
                        value={prestacionForm.pagaduria}
                        name="pagaduria"
                        onChange={(event) => {
                            saveData(event)
                        }}
                        type="text" 
                        className="form-control" 
                        id="basic-url6" 
                        aria-describedby="basic-addon3" />
                    </div>
                </div>
                {/*<div className="col-md-6">
                    <label 
                    className="color_primario"
                    htmlFor="basic-url5">Adjuntar volante(opcional)</label>
                    <div className="input-group mb-3">
                        <div className="form-group campo_archivo">
                            <label htmlFor={"volante"}>
                                { prestacionForm.volante_pago.nombre? prestacionForm.volante_pago.nombre : 'Subir archivo'}
                            </label>
                            <input 
                            onChange={(event) => {
                                saveFile(event)
                            }}
                            name="volante_pago"
                            type="file" 
                            className="form-control-file" 
                            id={"volante"} style={{display: 'none'}} />
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
        </Element>
    )
}
