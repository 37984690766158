export default function ModalPrivacidad() {
    return (
        <div 
        id="privacidadModal"
        className="modal fade">
            <div className="modal-dialog">
                <div className="modal-content">
                    <a
                    href={() => false}
                    data-dismiss="modal"
                    className="close-modal">x</a>
                    <h2>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</h2>
                    <div className="space-md"></div>
                    <p className="m-0" style={{
                        maxHeight: 500,
                        overflowY: 'scroll'
                    }}>
                        1. OBJETIVO
                        Garantizar un tratamiento adecuado de los datos personales que maneja la compañía dando cumplimiento a la Ley 1581 de 2012, del Decreto Reglamentario 1377 de 2013, Decreto Único Reglamentario No 1074 de 2015 y de las demás normas que los deroguen, modifiquen o complementen.
                        2. ALCANCE
                        Aplica para las bases de datos y archivos que contengan información personal de proveedores, clientes, colaboradores o cualquier otra persona cuya información sea objeto de tratamiento por parte de UPGRADE CREDITICIO S.A.S.
                        Aplica para los colaboradores de todas las áreas que en el ejercicio de sus funciones tratan datos personales de proveedores, clientes, colaboradores o cualquier otra persona natural.
                        3. DEFINICIONES
                        Para los efectos de la siguiente política se entenderá́por:

                        Autorización: Consentimiento previo, expreso e informado del titular para llevar a cabo el tratamiento de sus datos personales.
                        Base de datos: Conjunto organizado de datos personales que sea objeto de tratamiento de conformidad con la Ley.
                        Archivos: Conjunto de documentos conservados por la empresa en donde conste información personal regulada por la ley.
                        Dato personal: Información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.
                        Datos sensibles: Aquellos que afectan la intimidad del titular o cuyo uso indebido puede generar discriminación, como, por ejemplo: aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales o derechos humanos, los datos relativos a la salud, a la vida sexual y a los datos biométricos.
                        Encargado del tratamiento: La persona natural o jurídica que realice el tratamiento de los datos personales por cuenta de la Compañía.
                        Responsable del tratamiento: Persona natural o jurídica que decida sobre las bases y tratamiento de los datos.
                        Tercero: Cualquier persona natural o jurídica diferente a las personas que pertenecen a Upgrade Crediticio S.A.S.
                        Titular: Persona natural cuyos datos personales son objeto de tratamiento.
                        Transferencia: La transferencia de datos tiene lugar cuando el responsable y/o encargado del tratamiento de datos personales ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es responsable del tratamiento y se encuentra dentro o fuera del país. 2.
                        Transmisión: Tratamiento de los datos personales que implica la comunicación de los mismos dentro o fuera del territorio colombiano cuando tenga por objeto la realización de un tratamiento por el encargado por cuenta del responsable.
                        Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales como la recolección, almacenamiento, uso, circulación o supresión.
                        4. POLÍTICA

                        4.1. Generales

                        4.1.1. Upgrade Crediticio S.A.S., en adelante la Compañía, identificada con NIT 900.930.045-6, con domicilio principal en la Calle 4 Norte 1-29 Barrio Centenario en la ciudad de Cali, Valle, página web www.upgradecrediticio.com, dueña de la marca Bancarízate, se hace responsable del tratamiento de datos personales que aparezcan registrados en sus bases de datos y archivos de conformidad con la Ley 1581 de 2012, el Decreto Reglamentario 1377 de 2013, Decreto Único Reglamentario No 1074 de 2015 y las demás normas que los deroguen, modifiquen o complementen.

                        4.1.2. Para efectos de alguna solicitud o reclamación relacionada con la presente política se pone a disposición el correo electrónico contacto@upgradecrediticio.com o al bancarizate@upgradecrediticio.com y el Teléfono: (57-2) 8801495.

                        4.1.3. Toda área o colaborador de la Compañía que por sus funciones esté encargado del tratamiento de bases de datos con información personal debe cumplir con lo dispuesto en la política y procedimiento del presente documento.

                        4.1.4. La compañía debe inscribir en el Registro Nacional de Bases de Datos las bases que contengan datos personales sujetos a tratamiento.

                        4.2. Tratamiento de la información

                        4.2.1. Cumplimiento de la Ley

                        La Compañía cumple estrictamente los requerimientos de ley sobre Protección de Datos Personales, en especial la Ley 1581 de 2012, el Decreto Reglamentario 1377 de 2013, Decreto Único Reglamentario No 1074 de 2015 y las demás normas que los deroguen, modifiquen o complementen.

                        4.2.2. Finalidad

                        La Compañía informa a los titulares la finalidad específica del tratamiento de sus datos personales, el cual en todo caso tendrá como fin principal realizar la gestión contable, fiscal, administrativa, comercial, operativa, y de recursos humanos de la Compañía; así como el desarrollo de actividades de bienestar, salud, educación, cultura, y velar por la seguridad de personas y bienes relacionadas con la actividad de la misma. La Compañía suprime los datos personales recaudados cuando hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron obtenidos.

                        Pol Trat Datos Pers VER 03 Junio 20

                        La Compañía ejercerá el tratamiento de la información con el consentimiento previo, expreso e 3 informado del titular, el cual será obtenido a través de cualquier medio que pueda ser objeto de consulta posterior. No es necesaria la autorización cuando se trate de:

                        Información que sea requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial
                        Datos que sean de naturaleza pública
                        Casos de urgencia médica o sanitaria
                        Tratamiento de información autorizada por la ley para fines históricos, estadísticos o científicos
                        Datos relacionados con el Registro Civil de la persona
                        4.2.4. Veracidad

                        La información facilitada por el titular deberá ser veraz, completa, exacta, comprobable y actualizada. El titular garantiza la autenticidad de todos aquellos datos que comunique a la Compañía.

                        4.2.5. Acceso y circulación de la información

                        En el tratamiento de la información la Compañía se acoge a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la Ley y de la Constitución. En este sentido la Compañía sólo hará tratamiento de los datos con autorización del titular y en los casos previstos por la Ley. La información estará disponible en internet u otro medio de comunicación masivo siempre que el acceso esté controlado de acuerdo a las políticas de la Compañía y esté limitado a sus titulares o terceros autorizados para el tratamiento de la información.

                        4.2.6. Seguridad de la información

                        La Compañía cuenta con las medidas técnicas, humanas y administrativas necesarias para garantizar seguridad a los datos de carácter personal obtenidos y que reposan en sus bases de datos y archivos, evitando su adulteración, perdida, consulta o acceso no autorizado o fraudulento. La Compañía puede publicar bases de datos personales en la Intranet o Internet siempre que cuente con autorización previa del titular de los datos personales, se fijen restricciones para su acceso y la publicación esté aprobada por la Dirección de la Compañía.

                        4.2.7. Confidencialidad

                        La Compañía garantiza la reserva de la información, inclusive después de finalizadas las labores que comprende el tratamiento. Los colaboradores de la Compañía encargados del tratamiento de la información personal se comprometen a cumplir con lo dispuesto en la política y procedimiento del presente documento.

                        4.2.8. Datos sensibles

                        La Compañía únicamente puede tratar datos sensibles cuando:

                        El titular haya otorgado autorización explícita a dicho tratamiento
                        Sea necesario para salvaguardar el interés vital del titular y este se encuentre física o jurídicamente incapacitado. En estos eventos se requiere autorización de los representantes legales.
                        Se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.
                        Tenga una finalidad histórica, estadística o científica, siempre que se adopten medidas para la supresión de identidad de los titulares.
                        4.2.9. Derechos de los titulares

                        Los titulares de la información tratada por la Compañía tienen los siguientes derechos:

                        Conocer, actualizar y rectificar sus datos personales
                        Solicitar prueba de autorización otorgada a la Compañía, salvo en los casos que especifica la Ley que no requieren autorización : o Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial o Datos de naturaleza pública o Urgencia médica o sanitaria o Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos o Datos relacionados con el registro civil de las personas
                        Ser informado del uso que se le ha dado a sus datos personales.
                        Presentar ante la Superintendencia de Industria y Comercio quejas por incumplimiento a la Ley 1581 de 2012, el Decreto Reglamentario 1377 de 2013, Decreto Único Reglamentario No 1074 de 2015 y las demás normas que los deroguen, modifiquen o complementen.
                        Revocar la autorización y/o solicitar la supresión de datos cuando la Compañía no respete los principios, derechos y garantías constitucionales y legales.
                        Acceder a sus datos personales objeto de tratamiento por la Compañía.
                        El titular de la información puede presentar peticiones, consultas y reclamos a través de los correos contacto@upgradecrediticio.com y bancarizate@upgradecrediticio.com
                        5. PROCEDIMIENTO

                        5.1. Autorizaciones

                        La Compañía solicita autorización escrita a todo proveedor, cliente o colaborador del cual realice el tratamiento de datos personales, siempre que sea persona natural, para que sus datos puedan ser tratados de conformidad con la finalidad establecida en cada caso.

                        5.2. Consultas

                        El titular o el causahabiente que desee realizar consultas sobre su información personal lo podrá́ hacer a través del correo electrónico contacto@upgradecrediticio.com o a bancarizate@upgradecrediticio.com.

                        Por solicitud del titular, la Compañía suministrará toda la información contenida en el registro individual o vinculado con la identificación del titular.

                        La Compañía responderá la consulta en un término máximo de diez (10) días hábiles contados desde la fecha de recibo de la misma. Si no es posible dar respuesta en este lapso de tiempo, la Compañía informará al interesado los motivos de la demora y señalará la fecha de respuesta que no puede superar cinco (5) días hábiles siguientes al primer vencimiento.

                        5.3. Reclamos

                        El titular o el causahabiente podrá realizar un reclamo ante la Compañía para que su información personal sea objeto de corrección, actualización, supresión o cuando consideren que la Compañía incumple con la Ley 1581 de 2012, el Decreto Reglamentario 1377 de 2013, Decreto Único Reglamentario No 1074 de 2015 y las demás normas que los deroguen, modifiquen o complementen. Igualmente podrá revocar la autorización otorgada para el tratamiento de sus datos personales. Los reclamos se hacen a través del correo electrónico contacto@upgradecrediticio.com o a bancarizate@upgradecrediticio.com. Los reclamos deben contener:

                        Identificación del titular
                        Descripción de los hechos que dan a lugar el reclamo
                        Dirección
                        Documentos adjuntos (si aplica)
                        Si la persona encargada de recibir el reclamo detecta que los datos no están completos solicitará al interesado dentro de los cinco (5) días siguientes a la recepción del reclamo que se hagan las correcciones necesarias.

                        La persona encargada de recibir el reclamo dará respuesta del mismo. En caso de no ser competente para hacerlo, enviará en un término de máximo dos (2) días hábiles a quien debe dar respuesta e informará de tal situación al interesado.

                        La Compañía responderá al reclamo dentro de los quince (15) días hábiles contados desde el día siguiente al recibo del mismo. Si no es posible dar respuesta en este lapso de tiempo, la Compañía informará al interesado los motivos de la demora y señalará la fecha de respuesta, dentro de los ocho (8) días hábiles siguientes al primer vencimiento.

                        Los reclamos presentados por los titulares se deberán inscribir en el Registro Nacional de Bases de Datos 5.4. Transferencia y transmisión de la información La Compañía suministrará los datos personales objeto de tratamiento a las siguientes personas:

                        Titular de la información, sus causahabientes o representantes legales. 
                        Entidades públicas o administrativas en ejercicio de sus funciones legales o por orden judicial.
                        Terceros autorizados por el titular o por la ley.
                        La Compañía suministrará los datos personales a terceros siempre que cuenten con autorización del titular de la información con la finalidad de responder a los requerimientos normales de la operación de sus negocios. En este caso el tercero, a partir del momento en que reciba la información, se convierte en encargado del tratamiento de la misma y deberá cumplir con las obligaciones legales.

                        A todo tercero que por su relación con la Compañía se le encargue el tratamiento de datos personales se le debe pedir cláusula contractual donde se exprese el conocimiento de la ley y la responsabilidad en el cumplimiento de esta y así mismo, requerirá previa autorización por parte del titular para tratar sus datos personales.

                        5.5. Transferencias internacionales

                        La Compañía transfiere lo datos personales a terceros países siempre que estos proporcionen niveles adecuados de protección de datos de conformidad con los estándares fijados por la Superintendencia de Industria y Comercio sobre la materia, y cuando:

                        El titular otorgue su autorización expresa e inequívoca para la transferencia
                        El intercambio de datos sea exigido por razones de salud o higiene pública
                        Se trate de transferencias bancarias o bursátiles
                        Se trate de transferencia acordada en el marco de tratados internacionales en los cuales la República de Colombia sea parte
                        Sea necesario para la ejecución de un contrato entre el titular y el responsable del tratamiento o para la ejecución de medidas precontractuales.
                        Sea exigido legalmente para la salvaguardia del interés público o para fines judiciales
                        5.6. Excepciones

                        La presente política no aplica para las bases de datos y archivos que:

                        Tengan por finalidad la seguridad y defensa nacional, así como la prevención, detección, monitoreo y control del lavado de activos y el financiamiento del terrorismo.
                        Tengan por finalidad y contengan información de inteligencia y contrainteligencia.
                        Tengan por finalidad información periodística y otros contenidos editoriales.
                        5.7. Vigencia

                        La presente Política y Procedimiento entra en vigencia a partir del 01de junio de 2017 y hasta la expedición de otras o cambio sustancial de las mismas.

                        5.8. Versiones de la Política

                        Primera versión: Junio 01 de 2017 
                        Segunda versión: Mayo 10 de 2020
                        Tercera versión: Junio 05 de 2020
                    </p>
                </div>
            </div>
        </div>
    );
}
